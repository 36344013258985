<template>
  <section class="full-width-section">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'FullWidthSection'
}
</script>

<style lang="stylus">
  .full-width-section
    position: relative
    margin-right: calc(-5% - 18px)
    margin-left: calc(-5% - 18px)

    @media(min-width:$sm-breakpoint)
      margin-left: calc(-100vw / 2 + 568px / 2)
      margin-right: calc(-100vw / 2 + 568px / 2)
    @media(min-width:$md-breakpoint)
      margin-left: calc(-100vw / 2 + 650px / 2)
      margin-right: calc(-100vw / 2 + 650px / 2)
</style>