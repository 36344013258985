<template>
  <view-wrapper class="watch mb-0 pb-0" header-text="Watch the film many ways">
    <p>
      Watch <strong>THE WEST IS BURNING</strong> documentary film now or later. Simply pick the format that fits best for you: watch online, stream on demand, watch on PBS, or attend a hosted public screening.
    </p>

    <section class="mt-5 watch__online">
      <h2 class="mb-4">Watch Online</h2>

      <youtube-video
        :video-id="FEATURE_YOUTUBE_ID"
        :controls="true"
        poster-src="/img/poster.png"
        poster-delay="250"
      />
    </section>

    <donate-banner />

    <section>
      <h2 id="hosted-screenings" class="mb-3">Hosted Public Screenings</h2>
      <p>To join a public screening, contact the host directly.</p>

      <screening-list>
        <template slot="no-results">
          <p>
            Nothing near you? <router-link :to="{ name: 'host' }">Maybe you should host a screening!</router-link>
          </p>
        </template>
      </screening-list>

      <v-btn 
        class="ml-0"
        color="accent" 
        depressed
        :to="{ name:'host' }">
        Interested in hosting a screening?
      </v-btn>
    </section>

    <section class="watch__streaming">
      <h2 class="mb-4">Stream on Demand</h2>

      <!-- <a href="https://www.pbs.org/passport/videos/" target="_blank" rel="noopener">
        <img src="/img/logos/pbs-passport.png" alt="PBS Passport" />
      </a> -->
      <p>Watch an abbreviated version of the full-feature film <strong>BREAKOUT: THE WEST IS BURNING.</strong></p>
      <a href="https://curiositystream.com/" target="_blank" rel="noopener">
        <img src="/img/logos/CuriosityStream.png" alt="Curiosity Stream" />
      </a>
    </section>

    <section>
      <h2 class="mb-3" id="airings_anchor">Airings</h2>

      <airings-list :filter="$route.query.filter">
        <!-- <template slot="no-results">
          <p>
            Don’t see anything here? <a target="_blank" href="#" rel="noopener" @click.prevent="$vuetify.goTo('#hosted-screenings')">Search the screenings below.</a>
          </p>
        </template> -->
      </airings-list>
    </section>
  </view-wrapper>
</template>

<script>
import AiringsList from '@/components/AiringsList/AiringsList'
import DonateBanner from '@/components/DonateBanner/DonateBanner'
import ScreeningList from '@/components/ScreeningList/ScreeningList'
import { FEATURE_YOUTUBE_ID } from '@/utils/constants'

export default {
  name: 'watch',

  components: {
    AiringsList,
    DonateBanner,
    ScreeningList
  },

  mounted () {
    if (this.$route.query.filter) {
      this.$vuetify.goTo('#airings_anchor', {
        offset: 100
      })
    }
  },

  data() {
    return {
      FEATURE_YOUTUBE_ID
    }
  }
}
</script>

<style lang="stylus">
  .watch
    .donate-banner
      margin-top: 0
    .watch__streaming
      a
        margin-bottom: 16px
        display: block
      img
        max-width: 200px
        max-height: 90px
</style>
