<template>
   <div class="screening-list"> 
    <v-text-field
      v-if="screenings.length"
      class="screening-list__search mt-3"
      v-model="screenFilter"
      label="Filter by City or State"
      solo
      clearable
    />

    <v-progress-circular 
      v-if="loadingScreenings"
      :size="60"
      indeterminate>
    </v-progress-circular>

    <ul v-else class="screening-list__list">
      <li v-if="!filteredScreenings.length">
        <p>
          <strong>{{ screenFilter ? 'No Results' : 'No scheduled screenings' }}</strong>
        </p>
        <slot name="no-results">
        </slot>
      </li>
      <li v-for="screening in filteredScreenings" :key="screening.id">
        <v-layout align-center>
          <v-flex sm2>
            <strong>{{ getDate(screening.field_preferred_date) }}</strong>
            <br />
            {{ getTime(screening.field_preferred_date) }}
          </v-flex>
          <v-flex sm7>
            <a
              v-if="screening.field_org_website"
              :href="screening.field_org_website"
              target="_blank"
              rel="noopener">
              {{ screening.field_screening_location }}, {{ screening.field_org_name }}
            </a>
            <span v-else>{{ screening.field_screening_location }}, {{ screening.field_org_name }}</span>
          </v-flex>
          <v-flex sm3>{{ screening.field_city }}, {{ screening.field_state }}</v-flex>
        </v-layout>

        <v-divider class="my-3"/>
      </li>  
    </ul>
  </div>
</template>

<script>
import {
  actions as contentActions,
  getters as contentGetters
} from '@/mixins/connectors/content'
import {
  allApprovedScreenings
} from '@/api/utils/queries'

import moment from 'moment'

export default {
  name: 'screening-list',
  
  mixins: [contentActions, contentGetters],

  async created() {
    try {
      await this.fetchContentTypeAll({
        type: 'screening',
        filter: allApprovedScreenings
      })
    } catch(e) {
      throw e
    } finally {
      this.loadingScreenings = false
    }
  },

  data() {
    return {
      screenFilter: '',
      loadingScreenings: true
    }
  },

  computed: {
    screenings () {
      return this.getContentTypeByType('screening')
    },
    filteredScreenings () {
      return this.screenings
        .filter(s => {
          const filter = this.screenFilter || ''
          const state = s.field_state || ''
          const city = s.field_city || ''

          return state.trim().toLowerCase().includes(filter.trim().toLowerCase())
            || city.trim().toLowerCase().includes(filter.trim().toLowerCase())
        })
    }
  },

  methods: {
    getDate(date = '') {
      return moment.parseZone(date).format('MMM D, YYYY')
    },
    getTime(date = '') {
      return moment.parseZone(date).format('h:mm A')
    }
  }
}
</script>

<style lang="stylus">
  .screening-list
    .screening-list__list
      margin: 0px
      padding: 0
      list-style: none

    .screening-list__search
      max-width: 380px
</style>