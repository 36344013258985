<template>
  <full-width-section class="donate-banner accent white--text">
    <view-wrapper
      class="mb-0"
      no-header
    >
      <div class="my-5">
        <h3 class="white--text text-uppercase font-weight-bold">Support our film</h3>
        <p>Your support sustains Wallowa Resources efforts to inform and engage communities about why forest stewardship is vital and urgent. The megafires across the western U.S., and beyond, is our wake up call to act now.</p>
        <v-btn
          color="white"
          depressed
          class="btn-extra-wide ml-0 accent--text"
          href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=E140503&id=7"
          target="_blank"
          rel="noopener"
          @click="handleClick"
        >
          Donate Today
        </v-btn>
      </div>
    </view-wrapper>
  </full-width-section>
</template>

<script>
import FullWidthSection from '@/components/FullWidthSection/FullWidthSection'
import { mapActions } from 'vuex'

export default {
  name: 'DonateBanner',

  components: {
    FullWidthSection
  },

  methods: {
    ...mapActions(['generalEvent']),
    handleClick () {
      this.generalEvent({
        category: 'donate',
        action: 'click',
        label: 'Watch page'
      })
    }
  }
}
</script>

<style lang="stylus">
  .donate-banner
    h3
      font-size: 20px
</style>
