import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions([
      'fetchContentTypeAll',
      'fetchContentType',
      'addContentType',
      'addContentWithDebounce',
      'updateContentType',
      'updateContentWithDebounce',
      // 'deleteContentType'
    ])
  }
}