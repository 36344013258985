<template>
   <div class="airings-list"> 
    <v-text-field
      v-if="airings.length"
      class="airings-list__search my-3"
      v-model="airingFilter"
      label="Filter by Location, Broadcast or Call letter"
      solo
      block
      clearable
      hide-details
    />

    <!-- <v-progress-circular 
      v-if="loadingAirings && !airings.length"
      :size="60"
      indeterminate>
    </v-progress-circular> -->

    <div
      v-if="loadingAirings"
      class="mb-3"
    >
     <em>{{ airings.length }} airings loaded...</em>
   </div>

    <ul class="airings-list__list">
      <li v-if="!filteredAirings.length">
        <p>
          <strong>{{ airingFilter ? 'No Results' : 'No scheduled airings' }}</strong>
        </p>
        <slot name="no-results">
        </slot>
      </li>
      <li v-for="airing in filteredAirings" :key="airing.id">
        <v-layout align-center>
          <v-flex sm4 xs3>
            <strong>{{ getDate(airing.field_air_date) }}</strong>
            <br v-if="$vuetify.breakpoint.xsOnly" />
            {{ getTime(airing.field_air_date) }}
            <span v-if="airing.field_timezone">
              ({{ airing.field_timezone }})
            </span>
          </v-flex>
          <v-flex sm4>
            {{
              airing.field_city && airing.field_state_list
                ? `${airing.field_city}, `
                : airing.field_city
            }}
            {{ airing.field_state_list }}
          </v-flex>
          <v-flex sm4>
            <a
              v-if="airing.field_broadcast_link"
              :href="airing.field_broadcast_link"
              target="_blank"
              rel="noopener">
              {{ airing.field_station }}
            </a>
            <span v-else>{{ airing.field_station }}</span>
          </v-flex>
          <!-- <v-flex sm3>{{ airing.field_station }}</v-flex> -->
        </v-layout>

        <v-divider class="my-3"/>
      </li>  
    </ul>

    <v-btn 
      v-if="showSeeAllButton"
      class="ml-0"
      color="accent"
      outline
      depressed
      @click="seeAll = !seeAll"
    >
      {{ seeAll ? 'Show Less' : `Show All ${airings.length}` }}
    </v-btn>
  </div>
</template>

<script>
import {
  actions as contentActions,
  getters as contentGetters
} from '@/mixins/connectors/content'
import {
  allAirings
} from '@/api/utils/queries'

import moment from 'moment'

export default {
  name: 'airings-list',
  
  mixins: [contentActions, contentGetters],

  async created() {
    try {
      await this.fetchContentTypeAll({
        type: this.type,
        filter: allAirings
      })
    } catch(e) {
      throw e
    } finally {
      this.loadingAirings = false
    }
  },

  props: {
    filter: {
      type: String,
      default: ''
    },
    limit: {
      type: [String, Number],
      default: 20
    }
  },

  data() {
    return {
      type: 'airings',
      airingFilter: this.filter,
      loadingAirings: true,
      seeAll: false
    }
  },

  computed: {
    showSeeAllButton () {
      return !this.airingFilter
        && this.airings.length > this.limit
    },
    airings () {
      return this.getContentTypeByType(this.type)
    },
    sliceAirings () {
      return this.seeAll || this.airingFilter
        ? this.airings
        : this.airings.slice().splice(0, this.limit)
    },
    filteredAirings () {
      return this.sliceAirings
        .filter(s => {
          const filter = this.airingFilter || ''
          const city = s.field_city || ''
          const state = s.field_state_list || ''
          const title = s.title || ''
          const station = s.field_station || ''

          return state.trim().toLowerCase().includes(filter.trim().toLowerCase())
            || city.trim().toLowerCase().includes(filter.trim().toLowerCase())
            || title.trim().toLowerCase().includes(filter.trim().toLowerCase())
            || station.trim().toLowerCase().includes(filter.trim().toLowerCase())
        })
    }
  },

  methods: {
    getDate(date = '') {
      return moment.parseZone(date).format('MMM D, YYYY')
    },
    getTime(date = '') {
      return moment.parseZone(date).format('h:mm A')
    }
  }
}
</script>

<style lang="stylus">
  .airings-list
    .airings-list__list
      margin: 0px
      padding: 0
      list-style: none

    .airings-list__search
      max-width: 380px

      &.v-text-field .v-label
        max-width: 100%
        text-overflow: unset
</style>