import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getContentTypeById',
      'getContentTypeByNodeId',
      'getContentTypeByType',
      'getSingleContentTypeByType',
      'getSingleContentTypeProperty'
    ]),
  }
}