// https://www.drupal.org/docs/8/modules/jsonapi/jsonapi
// https://www.drupal.org/docs/8/core/modules/jsonapi-module/filtering
import moment from 'moment'

export const allStories = {
  include: 'field_image,field_cbo',
  'fields[node--community_based_organization]': 'title',
  'fields[file--file]': 'uri,url',
  'sort': 'field_order'
}

export const allApprovedScreenings = {
  'filter[field_hide_screening]': false,
  'filter[field_approved_status]': 'Approved',
  'filter[field_screening_type]': 'Public',
  'filter[datefilter][condition][path]': 'field_preferred_date',
  'filter[datefilter][condition][operator]': '>',
  'filter[datefilter][condition][value]': moment().subtract(1, 'day').format(),
  'sort': 'field_preferred_date'
}

export const allAirings = {
  'filter[datefilter][condition][path]': 'field_air_date',
  'filter[datefilter][condition][operator]': '>',
  'filter[datefilter][condition][value]': moment().subtract(1, 'hour').format(),
  'sort': 'field_air_date'
}

export const allPress = {
  'sort': 'field_order'
}

export const allCbos = {
  sort: 'field_state_list'
}
